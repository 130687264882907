<template>
  <div class="inner-container" :class="{ 'min-container': !getTabs }">
    <stepperGeneric :steps="steps" @complete="handleCompletion"></stepperGeneric>
  </div>
</template>

<script>
import stepperGeneric from '../../components/stepper/devisLibre/stepperGeneric.vue';
import stepAdresseLibre from '../../components/stepper/devisLibre/stepAdresseLibre.vue';
import stepChaniterLibre from '../../components/stepper/devisLibre/stepChaniterLibre.vue';
import stepClientLibre from '../../components/stepper/devisLibre/stepClientLibre.vue';
import stepGeneraliteLibre from '../../components/stepper/devisLibre/stepGeneraliteLibre.vue';
import stepInformationLibre from '../../components/stepper/devisLibre/stepInformationLibre.vue';
import stepInstallateurLibre from '../../components/stepper/devisLibre/stepInstallateurLibre.vue';
import stepReglementLibre from '../../components/stepper/devisLibre/stepReglementLibre.vue';
import stepDevisLibre from '../../components/stepper/devisLibre/stepDevisLibre.vue';

import { mapGetters } from "vuex";

export default {
  components: {
    stepperGeneric,
  
  },
  data() {
    return {
      steps: [
        { label: 'Généralité', component: stepGeneraliteLibre },
        { label: 'Client', component: stepClientLibre },
        { label: 'Installateur', component: stepInstallateurLibre },
        { label: 'Adresse', component: stepAdresseLibre },
        { label: 'Information', component: stepInformationLibre },
        { label: 'Chantier', component: stepChaniterLibre },
        { label: 'Règlement', component: stepReglementLibre },
        { label: 'Devis', component: stepDevisLibre },
      ],
    };
  },
  computed: {
    ...mapGetters(["getTabs"]),
  },
  methods: {
    handleCompletion() {
      this.$router.push('/devis/list');
    },
  },
};
</script>

  
  <style scoped>
  .inner-container .content {
    margin: 10px 15px;
    background-color: #fff;
    box-shadow: 1px 1px 24px #00000019;
    border-radius: 5px;
    padding: 14px;
    min-height: 88vh;
    margin-top: 55px;
  }
  </style>